'use strict';

require('../main').run(/*@ngInject*/function(
    $document, $rootScope) {

  document.addEventListener('touchstart', handleTouchStart, false);
  document.addEventListener('touchmove', handleTouchMove, false);

  var maxTime = 1000;
  var minDistance = 30;

  var xDown = null;
  var yDown = null;
  var startTime = null;

  function handleTouchStart(evt) {
    xDown = evt.touches[0].clientX;
    yDown = evt.touches[0].clientY;
    startTime = (new Date()).getTime();
  }

  function handleTouchMove(evt) {
    if (!xDown || !yDown) {
      return;
    }

    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;
    var now = (new Date()).getTime();

    var xDiff = xDown - xUp;
    var yDiff = yDown - yUp;
    var timeDiff = now - startTime;

    if (timeDiff > maxTime) {
      return;
    } else if (Math.abs(xDiff) < minDistance &&
               Math.abs(yDiff) < minDistance){
      return;
    }

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        $rootScope.$broadcast('swipeLeft');
      } else {
        $rootScope.$broadcast('swipeRight');
      }
    } else {
      if (yDiff > 0) {
        $rootScope.$broadcast('swipeUp');
      } else {
        $rootScope.$broadcast('swipeDown');
      }
    }

    xDown = null;
    yDown = null;
    startTime = null;
  }
});
