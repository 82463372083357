"use strict";

require('../main').controller('RecipeListController',
  /*@ngInject*/function ($scope, $rootScope, $location, RecipeService) {
    //var controller = this;

    function refreshRecipes(){
      RecipeService.data().then(function (data){
        $scope.categories = data.categories();
      });
    }
    $rootScope.openCategories = $rootScope.openCategories || {};
    refreshRecipes();

    $scope.openRecipe = function (id) {
      $location.path('/recipes/' + id);
    };

    $scope.openInfos = function () {
      $location.path('/infos');
    };

    $scope.$on('recipesUpdated', function() {
      refreshRecipes();
    });
  });
