'use strict';

require('../main').service('routeScrollService', /*@ngInject*/function(
    $window, $timeout, $location, $rootScope) {

  var scrollPos = {};
  var currentPage;
  $rootScope.$on('$routeChangeStart', function() {
    scrollPos[currentPage] = $window.pageYOffset;
  });

  $rootScope.$on('$routeChangeSuccess', function(e, current) {
    var scrollTo;
    if (current.$$route.storeScroll){
      currentPage = $location.path();
      scrollTo = scrollPos[currentPage] || 0;
    } else {
      currentPage = undefined;
      scrollTo = 0;
    }
    var doScroll = function() {
      $window.scrollTo(0, scrollTo);
    };
    $timeout(doScroll, 0);
    doScroll();
  });

  return {
  };
}).run(/*@ngInject*/function(routeScrollService) {
  routeScrollService;
});
