"use strict";

require('../main').controller('RecipeController', /*@ngInject*/function (
      $scope, $routeParams, $location, RecipeService, NoSleep,
      $timeout, recipeControllerState) {
  var recipeId = parseInt($routeParams.recipeId);

  var prevRecipe;
  var nextRecipe;
  var prevImage = document.createElement('img');
  var nextImage = document.createElement('img');

  function refreshRecipe(){
    RecipeService.data().then(function(data){
      $scope.recipe = data.recipe(recipeId);
      prevRecipe = data.prevRecipe(recipeId);
      nextRecipe = data.nextRecipe(recipeId);
      prevImage.setAttribute("src", prevRecipe.image_url);
      nextImage.setAttribute("src", nextRecipe.image_url);
    });
  }
  refreshRecipe();

  $scope.$on('recipesUpdated', function() {
    refreshRecipe();
  });

  $scope.viewClasses = recipeControllerState.nextViewClasses;
  recipeControllerState.nextViewClasses = [];

  function openRecipe(id){
    $timeout(function(){
      $location.path('/recipes/' + id);
    });
  }

  $scope.prevRecipe = function(){
    $scope.viewClasses = ["out-right"];
    recipeControllerState.nextViewClasses = ["in-left"];
    openRecipe(prevRecipe.id);
  };
  $scope.nextRecipe = function(){
    $scope.viewClasses = ["out-left"];
    recipeControllerState.nextViewClasses = ["in-right"];
    openRecipe(nextRecipe.id);
  };

  NoSleep.enable();
  $scope.$on("$destroy", function handler() {
    NoSleep.disable();
  });
}).service('recipeControllerState', function(){
  return {
    nextViewClasses: [],
  };
});
