require('../main').service('RecipeService',
/*@ngInject*/function ($http, $window, $rootScope) {
  function getRecipes() {
    return $http({
      url: '/api/recipes',
    }).then(function (response){
      $rootScope.$broadcast('recipesUpdated');
      var categories = response.data;
      var recipeMap = {};
      var recipeIdList = [];
      angular.forEach(categories, function(category){
        angular.forEach(category.recipes, function(recipe){
          recipeMap[recipe.id] = recipe;
          recipeIdList.push(recipe.id);
        });
      });
      return {
        categories: categories,
        recipeMap: recipeMap,
        recipeIdList: recipeIdList,
      };
    });
  }

  var dataPromise = getRecipes();

  $window.applicationCache.addEventListener('updateready', function(){
    $window.applicationCache.swapCache();
    // Reload recipes
    dataPromise = getRecipes();
  });

  return {
    data: function(){
      return dataPromise.then(function(data){
        return {
          categories: function() {
            return data.categories;
          },
          recipe: function(id) {
            return data.recipeMap[id];
          },
          nextRecipe: function(id) {
            var idx = data.recipeIdList.indexOf(id);
            var nextIdx = (idx + 1) % data.recipeIdList.length;
            return data.recipeMap[data.recipeIdList[nextIdx]];
          },
          prevRecipe: function(id) {
            var idx = data.recipeIdList.indexOf(id);
            var prevIdx = (idx - 1 + data.recipeIdList.length) % data.recipeIdList.length;
            return data.recipeMap[data.recipeIdList[prevIdx]];
          },
        };
      });
    }
  };
});
