'use strict';

require('../main').directive('tabSwipe', /*@ngInject*/function() {
  return {
    require: 'mdTabs',
    link: function(scope, element, attrs, tabController){
      scope.$on('swipeRight', function(){
        scope.$apply(function(){
          tabController.incrementIndex(-1);
        });
      });
      scope.$on('swipeLeft', function(){
        scope.$apply(function(){
          tabController.incrementIndex(1);
        });
      });
    },
  };
});
