require('../main').service('NoSleep', /*@ngInject*/function() {
  // UA matching
  var ua = {
    Android: /Android/ig.test(navigator.userAgent),
    iOS: /AppleWebKit/.test(navigator.userAgent) && /Mobile\/\w+/.test(navigator.userAgent)
  };

  var media = {
    WebM: "data:video/webm;base64,GkXfo0AgQoaBAUL3gQFC8oEEQvOBCEKCQAR3ZWJtQoeBAkKFgQIYU4BnQI0VSalmQCgq17FAAw9CQE2AQAZ3aGFtbXlXQUAGd2hhbW15RIlACECPQAAAAAAAFlSua0AxrkAu14EBY8WBAZyBACK1nEADdW5khkAFVl9WUDglhohAA1ZQOIOBAeBABrCBCLqBCB9DtnVAIueBAKNAHIEAAIAwAQCdASoIAAgAAUAmJaQAA3AA/vz0AAA=",
    MP4: "data:video/mp4;base64,AAAAHGZ0eXBpc29tAAACAGlzb21pc28ybXA0MQAAAAhmcmVlAAAAG21kYXQAAAGzABAHAAABthADAowdbb9/AAAC6W1vb3YAAABsbXZoZAAAAAB8JbCAfCWwgAAAA+gAAAAAAAEAAAEAAAAAAAAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIAAAIVdHJhawAAAFx0a2hkAAAAD3wlsIB8JbCAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAQAAAAAAIAAAACAAAAAABsW1kaWEAAAAgbWRoZAAAAAB8JbCAfCWwgAAAA+gAAAAAVcQAAAAAAC1oZGxyAAAAAAAAAAB2aWRlAAAAAAAAAAAAAAAAVmlkZW9IYW5kbGVyAAAAAVxtaW5mAAAAFHZtaGQAAAABAAAAAAAAAAAAAAAkZGluZgAAABxkcmVmAAAAAAAAAAEAAAAMdXJsIAAAAAEAAAEcc3RibAAAALhzdHNkAAAAAAAAAAEAAACobXA0dgAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAAAIAAgASAAAAEgAAAAAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABj//wAAAFJlc2RzAAAAAANEAAEABDwgEQAAAAADDUAAAAAABS0AAAGwAQAAAbWJEwAAAQAAAAEgAMSNiB9FAEQBFGMAAAGyTGF2YzUyLjg3LjQGAQIAAAAYc3R0cwAAAAAAAAABAAAAAQAAAAAAAAAcc3RzYwAAAAAAAAABAAAAAQAAAAEAAAABAAAAFHN0c3oAAAAAAAAAEwAAAAEAAAAUc3RjbwAAAAAAAAABAAAALAAAAGB1ZHRhAAAAWG1ldGEAAAAAAAAAIWhkbHIAAAAAAAAAAG1kaXJhcHBsAAAAAAAAAAAAAAAAK2lsc3QAAAAjqXRvbwAAABtkYXRhAAAAAQAAAABMYXZmNTIuNzguMw=="
  };

  function addSourceToVideo(element, type, dataURI) {
    var source = document.createElement('source');
    source.src = dataURI;
    source.type = "video/" + type;
    element.appendChild(source);
  }

  var noSleepTimer;
  var noSleepVideo;

  function init() {
    if (ua.iOS) {
      noSleepTimer = null;
    } else if (ua.Android) {
      // Set up no sleep video element
      noSleepVideo = document.createElement('video');
      noSleepVideo.setAttribute("loop", "");

      // Append nosleep video sources
      addSourceToVideo(noSleepVideo, "webm", media.WebM);
      addSourceToVideo(noSleepVideo, "mp4", media.MP4);

      document.addEventListener('click', clickHandler, false);
    }
  }
  function clickHandler(){
    document.removeEventListener('click', clickHandler, false);
    window.setInterval(videoCycle, 10000);
  }

  function videoCycle() {
    if (!isEnabled)
      return;
    noSleepVideo.play().then(function(){
      window.setTimeout(function(){
        noSleepVideo.pause();
      }, 100);
    });
  }

  var isEnabled = false;

  function enable(duration) {
    if (ua.iOS) {
      disable();
      isEnabled = true;
      noSleepTimer = window.setInterval(function() {
        window.location.href = window.location.href;
        window.setTimeout(window.stop, 0);
      }, duration || 15000);
      return;
    } else if (ua.Android) {
      isEnabled = true;
      return;
    }
  }

  function disable() {
    isEnabled = false;
    if (noSleepTimer) {
      window.clearInterval(noSleepTimer);
      noSleepTimer = null;
    }
  }

  return {
    'init': init,
    'enable': enable,
    'disable': disable,
  };
}).run(/*@ngInject*/function(NoSleep){
  NoSleep.init();
});
