"use strict";

require('../main').controller('RootController',
  /*@ngInject*/function ($scope, $location) {
    $scope.$on('$routeChangeSuccess', function(e, current) {
      $scope.showBackArrow = current.$$route.name !== 'recipe-list';
    });
    $scope.backArrow = function(){
      $location.path('/recipes');
    };
  });
