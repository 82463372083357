"use strict";

var angular = require('angular');


require('angular-aria');

var dependencies = [
  require('angular-material'),
  require('angular-animate'),
  require('angular-route'),
];

module.exports = angular.module('cookbook', dependencies)
  .config(/*@ngInject*/function ($locationProvider) {
    // we like to parse the html w/o hashbang but don't use the base
    $locationProvider.html5Mode({
      enabled: true,
      requireBase: false,
      rewriteLinks: false
    });
  })
  .config(function($mdThemingProvider) {
    $mdThemingProvider.theme('default')
      .primaryPalette('teal')
      .accentPalette('deep-orange');
  })
  .config(function($routeProvider) {
    $routeProvider
      .when("/infos", {
        name: 'infos',
        template: eval(require("./templates/infos.html")),
      })
      .when("/recipes", {
        name: "recipe-list",
        storeScroll: true,
        controller: 'RecipeListController',
        template: eval(require("./templates/recipe-list.html")),
      })
      .when("/recipes/:recipeId", {
        name: "recipe-display",
        controller: 'RecipeController',
        template: eval(require("./templates/recipe.html")),
      })
      .otherwise('/recipes');
  }).config(function($sceProvider) {
    $sceProvider.enabled(false);
  })
  .run(function($rootScope){
    $rootScope.appVersion = window.appVersion;
  });

// include all js files
({"controllers":({"recipeController":require("./controllers/recipeController.js"),"recipeListController":require("./controllers/recipeListController.js"),"rootController":require("./controllers/rootController.js")}),"directives":({"tabSwipe":require("./directives/tabSwipe.js")}),"services":({"gestures":require("./services/gestures.js"),"noSleep":require("./services/noSleep.js"),"recipeService":require("./services/recipeService.js"),"routeScrollService":require("./services/routeScrollService.js")})});

angular.element(document).ready(function() {
  angular.bootstrap(document, ['cookbook']);
});
